var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row table-row" }, [
    _c("div", { staticClass: "col-3" }, [
      _vm._v(
        _vm._s(_vm.vehicle._doc.brandName) +
          " " +
          _vm._s(_vm.vehicle._doc.modelName) +
          " " +
          _vm._s(_vm.vehicle._doc.engineName)
      )
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "col-2" }, [
      _vm._v(_vm._s(_vm.vehicle._doc.modelYear))
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "col-1" }, [
      _vm._v(_vm._s(_vm.vehicle._doc.baseColorName))
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "col-2" }, [
      _vm._v(_vm._s(_vm.vehicle.registrationDate))
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "col-2" }, [
      _vm._v(_vm._s(_vm.vehicle._doc.endDate))
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "col-2" }, [
      _c(
        "button",
        { staticClass: "btn btn--primary btn--sm", on: { click: _vm.onClick } },
        [_vm._v("Prisförslag")]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }