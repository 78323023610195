<template>
  <VcModal v-if="contactModalOpen" @close="onCloseContactModal" small>
    <div slot="header">
      Personligt prisförslag
    </div>
    <div v-if="!formSent" slot="content" class="vehile-list-simple-contact-form">
      <div class="contact-forms">
        <form class="contact-forms__form form-feedback-host">
          <h5>
            <strong>
              Kontakta oss för att få prisförslag
            </strong>
          </h5>

          <p>
            <small>
              Fyll i dina uppgifter så återkommer vi med ett personligt erbjudande.
            </small>
          </p>

          <div class="form-row">
            <div class="form-row__col">
              <div class="form-stack form-stack--required">
                <label>
                  E-post
                </label>
                <input v-model="customerEmail" class="form-control" type="email" autocomplete="email"
                  name="customerEmail" placeholder="Din e-post" required="">
              </div>
            </div>
          </div>

          <button @click.prevent="submitContactRequest" class="btn btn--primary btn--block">
            Skicka förfrågan
          </button>

          <div class="form-text">
            <p>Alla personuppgifter som skickas in till Holmgrens Bil kommer att behandlas enligt bestämmelserna i EU:s
              dataskyddsförordningen (GDPR).&nbsp;<span style="text-decoration: underline;"><a
                  title="Läs om hur vi behandlar din personuppgifter"
                  href="/om-oss/personuppgiftspolicy">Här</a></span>&nbsp;kan du läsa mer om hur vi
              behandlar dina personuppgifter.</p>
          </div>
        </form>
      </div>
    </div>
    <div v-else slot="content">
      <div v-if="isLoading" class="vehile-list-simple-contact-form__loader">
        <FontAwesomeIcon :icon="faSpinnerThird" :spin="true">
        </FontAwesomeIcon>
      </div>
      <div v-else>
        <div class="form-feedback">
          <div class="loader loader--success" v-if="!error">
            <div class="loader__shape">
              <div class="loader__symbol">
              </div>
            </div>
            <div class="loader__header">
              Tack för ditt intresse!
            </div>
            <div class="loader__text">
              Vi hör av oss inom kort.
            </div>
          </div>
          <div class="loader loader--error" v-else>
            <div class="loader__shape">
              <div class="loader__symbol">
              </div>
            </div>
            <div class="loader__header">
              Något gick fel
            </div>
            <div class="loader__text">
              Vänligen försök igen eller kontakta oss
            </div>
          </div>
        </div>
      </div>
    </div>
  </VcModal>
</template>

<script>
import { VcModal } from "@holmgrensbil/vue-components";
import { faSpinnerThird } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import Axios from "axios";
import { EventBus } from "../../utils/event-bus";

export default {
  components: {
    VcModal
  },
  data() {
    return {
      contactModalOpen: false,
      formSent: false,
      isLoading: false,
      error: false,
      customerEmail: "",
      vehicleId: ""
    };
  },
  async created() {
    EventBus.$on("open-vehicle-list-simple-contact-modal", this.onOpenModal.bind(this));
  },
  methods: {
    async onOpenModal({ vehicleId }) {
      this.vehicleId = vehicleId;
      this.contactModalOpen = true;
    },
    onCloseContactModal() {
      this.contactModalOpen = false;
      this.clearInputs();
    },
    clearInputs() {
      this.customerEmail = "";
      this.vehicleId = "";
      this.isLoading = false;
      this.formSent = false;
      this.error = false;
    },
    async submitContactRequest() {
      try {
        console.log(this.customerEmail);
        if (this.vehicleId && this.customerEmail && this.customerEmail !== '' && this.$validateEmail(this.customerEmail)) {
          this.isLoading = true;
          this.formSent = true;
          let contactData = {
            vehicleId: this.vehicleId,
            customerEmail: this.customerEmail
          };
          await Axios.post("/api/Contact/SendPrivateLeasingReturnForm", contactData);
        }
      } catch (e) {
        console.warn(e);
        this.error = true;
      } finally {
        this.isLoading = false;
      }
    },
  }
};
</script>