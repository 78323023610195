export default class I3DocProduct {
  constructor(doc) {
    this._doc = doc;
  }

  get id() {
    return this._doc._id;
  }

  get registrationDate() {
    var d = new Date(this._doc.registrationDate),
      month = '' + (d.getMonth() + 1),
      year = d.getFullYear();

    return [year, month].join('-');
  }
}
