<template>
    <div class="row table-row">
        <div class="col-3">{{ vehicle._doc.brandName }} {{ vehicle._doc.modelName }} {{ vehicle._doc.engineName }}</div>
        <div class="col-2">{{ vehicle._doc.modelYear }}</div>
        <div class="col-1">{{ vehicle._doc.baseColorName }}</div>
        <div class="col-2">{{ vehicle.registrationDate }}</div>
        <div class="col-2">{{ vehicle._doc.endDate }}</div>
        <div class="col-2"><button class="btn btn--primary btn--sm" @click="onClick">Prisförslag</button></div>
    </div>
</template>

<script>
export default {
  props: {
    vehicle: Object,
  },
  methods: {
    onClick() {
      this.$emit('click');
    },
  },
};
</script>
